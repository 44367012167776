import { Button } from "antd";

/**
 * Dial pad button properties.
 */
type DialPadButtonProps = {
  /**
   * Indicates that the buttons on the dial pad are disabled.
   */
  disabled?: boolean;
  /**
   * The label of the button.
   */
  label: string;
  /**
   * Callback when the user clicks the button.
   */
  onClick: (args: {
    value: string | number;
  }) => void;
  /**
   * The value of the button.
   */
  value: string | number;
};

/**
 * A single button in the dial pad.
 */
const DialPadButton = ({
  disabled,
  label,
  onClick,
  value
}: DialPadButtonProps) => {
  return <Button disabled={disabled} icon={<div className={`flex items-center justify-center gap-2.5 rounded-full p-2.5`}>
          <div className="inline-flex flex-col items-center justify-start">
            <div className="text-[24px] font-semibold leading-[44.20px] text-tpl-navy">
              {value}
            </div>
            <div className="text-sm font-semibold leading-[17.50px] text-gray-400">
              {label}
            </div>
          </div>
        </div>} onClick={() => onClick({
    value
  })} shape="circle" style={{
    height: 80,
    width: 80
  }} type="default" data-sentry-element="Button" data-sentry-component="DialPadButton" data-sentry-source-file="dial-pad.tsx" />;
};

/**
 * Dial pad buttons.
 */
const dialPadButtons = [{
  label: "-",
  value: 1
}, {
  label: "ABC",
  value: 2
}, {
  label: "DEF",
  value: 3
}, {
  label: "GHI",
  value: 4
}, {
  label: "JKL",
  value: 5
}, {
  label: "MNO",
  value: 6
}, {
  label: "PQRS",
  value: 7
}, {
  label: "TUV",
  value: 8
}, {
  label: "WXYZ",
  value: 9
}, {
  label: "",
  value: "*"
}, {
  label: "",
  value: 0
}, {
  label: "",
  value: "#"
}];

/**
 * Dial pad properties.
 */
export type DialPadProps = {
  /**
   * Indicates that the buttons on the dial pad are disabled.
   */
  disabled?: boolean;
  /**
   * Callback when the user clicks a dial pad button.
   */
  onClick: DialPadButtonProps["onClick"];
  /**
   * The size of the dial pad.
   */
  size?: number | string;
};

/**
 * A dial pad component which allows the user to enter a phone number.
 * Has buttons representing each number and a few special characters.
 */
export const DialPad = ({
  disabled,
  onClick,
  size = "[80px]"
}: DialPadProps) => {
  return <div className="bg-white p-8" data-sentry-component="DialPad" data-sentry-source-file="dial-pad.tsx">
      <div className="inline-flex w-full flex-col items-center justify-center gap-4">
        <div className="inline-flex items-start justify-start gap-4">
          <DialPadButton disabled={disabled} label="-" value={1} onClick={onClick} data-sentry-element="DialPadButton" data-sentry-source-file="dial-pad.tsx" />
          <DialPadButton disabled={disabled} label="ABC" value={2} onClick={onClick} data-sentry-element="DialPadButton" data-sentry-source-file="dial-pad.tsx" />
          <DialPadButton disabled={disabled} label="DEF" value={3} onClick={onClick} data-sentry-element="DialPadButton" data-sentry-source-file="dial-pad.tsx" />
        </div>
        <div className="inline-flex items-start justify-start gap-4">
          <DialPadButton disabled={disabled} label="GHI" value={4} onClick={onClick} data-sentry-element="DialPadButton" data-sentry-source-file="dial-pad.tsx" />
          <DialPadButton disabled={disabled} label="JKL" value={5} onClick={onClick} data-sentry-element="DialPadButton" data-sentry-source-file="dial-pad.tsx" />
          <DialPadButton disabled={disabled} label="MNO" value={6} onClick={onClick} data-sentry-element="DialPadButton" data-sentry-source-file="dial-pad.tsx" />
        </div>
        <div className="inline-flex items-start justify-start gap-4">
          <DialPadButton disabled={disabled} label="PQRS" value={7} onClick={onClick} data-sentry-element="DialPadButton" data-sentry-source-file="dial-pad.tsx" />
          <DialPadButton disabled={disabled} label="TUV" value={8} onClick={onClick} data-sentry-element="DialPadButton" data-sentry-source-file="dial-pad.tsx" />
          <DialPadButton disabled={disabled} label="WXYZ" value={9} onClick={onClick} data-sentry-element="DialPadButton" data-sentry-source-file="dial-pad.tsx" />
        </div>
        <div className="inline-flex items-start justify-start gap-4">
          <DialPadButton disabled={disabled} label="" value="*" onClick={onClick} data-sentry-element="DialPadButton" data-sentry-source-file="dial-pad.tsx" />
          <DialPadButton disabled={disabled} label="" value={0} onClick={onClick} data-sentry-element="DialPadButton" data-sentry-source-file="dial-pad.tsx" />
          <DialPadButton disabled={disabled} label="" value="#" onClick={onClick} data-sentry-element="DialPadButton" data-sentry-source-file="dial-pad.tsx" />
        </div>
      </div>
    </div>;
};