"use client";

/**
 * Third-party libraries.
 */
import { CheckCircleFilled as CheckCircleFilledIcon, WarningFilled as WarningFilledIcon } from "@ant-design/icons";
import { Button, Input, Select, Tooltip } from "antd";
import { useEffect, useState } from "react";

/**
 * Project components.
 */
import { COUNTRY, CountryDetails } from "@/components/client/country";
import { DialPad } from "@/components/client/dial-pad";
import { Icon } from "@/components/client/icon";
import { useSystemPreferenceContext } from "@/components/client/system-preference";
import { PhoneNumberErrorMessage } from "@/components/common/error/enumerations";
import { PhoneNumberUtility } from "@/components/common/phone-number";
import { PlayWrightTestId } from "@/tests/constants";

/**
 * Properties of the dialer component.
 */
export type DialerProps = {
  /**
   * Indicates if the dialer is disabled.
   */
  disabled?: boolean;
  /**
   * Indicates if the dialer is loading.
   *
   * Disables all the buttons.
   *
   * Shows a loading indicator on the call button.
   */
  loading?: boolean;
  /**
   * Callback when the user clicks the dial button.
   */
  onDial: (args: {
    /**
     * International phone number to call.
     *
     * @example
     * +6561234567
     */
    phoneNumber: string;
  }) => void;
};

/**
 * A dialer component which allows the user to make calls.
 * Contains an input field for the phone number, dial pad, contact list, and call controls.
 */
export function Dialer({
  disabled,
  loading,
  onDial
}: DialerProps) {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const {
    sessionInitationProtocol
  } = useSystemPreferenceContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * The message to be shown on top of the dialer input field.
   */
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  /**
   * The country that the phone number belongs to.
   *
   * @default
   * Singapore
   */
  const [country, setCountry] = useState<CountryDetails>(COUNTRY.Singapore);

  /**
   * The value of the dial pad input field.
   *
   * @example
   * +65 6 1234567
   *
   * @example
   * 65 6 1234567
   * @example
   * 65 6 123 4567
   *
   * @example
   * 656-1234-5678
   *
   * @example
   * +656-1234-567
   *
   * @example
   * 6561234567
   *
   * @example
   * 61234567
   */
  const [dialPadInputValue, setDialPadInputValue] = useState<string>("");

  /**
   * Error message to display when the phone number is invalid.
   */
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  /**
   * The phone number to call. This would only have a value if the dial pad
   * input value is a supported valid phone number.
   *
   * @example
   * 6561234567
   */
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  /**
   * Determines if the phone number is valid.
   * The phone number is only valid if:
   * - The country is provided.
   * - The phone number is provided.
   * - The phone number matches the country's phone number regex validator.
   */
  const validateAndSetPhoneNumberAndCountry = ({
    phoneNumber
  }: {
    phoneNumber: string;
  }) => {
    /**
     * Validated phone number.
     */
    const validatedPhoneNumber = PhoneNumberUtility.validate({
      /**
       * The phone number starts with "+", assume that the country code
       * is already included and validate that.
       *
       * Otherwise, include the country code and validate the phone number.
       */
      phoneNumber: phoneNumber.startsWith("+") ? phoneNumber : `+${country.code}${phoneNumber}`
    });
    if (!phoneNumber?.length) {
      setErrorMessage(null);
    }
    // Phone number is invalid.
    else if (!validatedPhoneNumber?.country || !validatedPhoneNumber?.phoneNumber) {
      setErrorMessage(PhoneNumberErrorMessage.INVALID_PHONE_NUMBER);
    } else if (`+${validatedPhoneNumber?.country.code}${validatedPhoneNumber?.phoneNumber}` === process.env.NEXT_PUBLIC_TWILIO_PHONE_NUMBER || `+${validatedPhoneNumber?.country.code}${validatedPhoneNumber?.phoneNumber}` === process.env.NEXT_PUBLIC_TWILIO_SESSION_INITIATION_PROTOCOL_PHONE_NUMBER) {
      setErrorMessage("Cannot call the system phone number.");
    }
    // Phone number is valid.
    else {
      setErrorMessage(null);
    }

    // Only set the country if the phone number starts with a "+".
    if (phoneNumber.startsWith("+") && validatedPhoneNumber?.country) {
      setCountry(validatedPhoneNumber?.country);
    }
    setDialPadInputValue(phoneNumber);
    setPhoneNumber(!!validatedPhoneNumber ? `${validatedPhoneNumber?.country.code}${validatedPhoneNumber?.phoneNumber}` : null);
    return validatedPhoneNumber;
  };

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Clears the alert message after a specific amount of time.
   */
  useEffect(() => {
    let timeOut: NodeJS.Timeout;
    if (alertMessage?.length) {
      setTimeout(() => {
        setAlertMessage(null);
      }, 3000);
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [alertMessage]);

  // /**
  //  * Clears the error message after a specific amount of time.
  //  */
  // useEffect(() => {
  //   let timeOut: NodeJS.Timeout;

  //   if (errorMessage?.length) {
  //     setTimeout(() => {
  //       setErrorMessage(null);
  //     }, 3000);
  //   }

  //   return () => {
  //     clearTimeout(timeOut);
  //   };
  // }, [errorMessage]);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return <div className="bg-red h-full w-full" data-sentry-component="Dialer" data-sentry-source-file="dialer.tsx">
      <div className="flex min-h-[135px] w-full flex-col border-b border-slate-200 bg-violet-50 py-4">
        <div className="flex w-full items-center justify-between gap-3 px-4 py-4">
          <Select className="bg-transparent" disabled={disabled || loading} options={Object.entries(COUNTRY).map(([key, value]) => {
          return {
            label: value.flag,
            value: value.code
          };
        })} onChange={value => {
          /**
           * The country details matching the selected flag.
           */
          const matchingCountry = Object.values(COUNTRY).find(country => country.code === value);
          if (!matchingCountry) {
            return;
          }
          setCountry(matchingCountry);

          /**
           * Details of the phone number on the dial pad input field.
           */
          const validatedPhoneNumber = PhoneNumberUtility.validate({
            /**
             * The phone number starts with "+", assume that the country code
             * is already included and validate that.
             *
             * Otherwise, include the country code and validate the phone number.
             */
            phoneNumber: dialPadInputValue.startsWith("+") ? dialPadInputValue : `+${country.code}${dialPadInputValue}`
          });
          if (validatedPhoneNumber?.phoneNumber) {
            validateAndSetPhoneNumberAndCountry({
              phoneNumber: `+${matchingCountry.code}${validatedPhoneNumber.phoneNumber}`
            });
          }
        }} size="large" value={country.code} data-sentry-element="Select" data-sentry-source-file="dialer.tsx" />
          <Input data-testid={PlayWrightTestId.Dialer.INPUT_BOX} disabled={disabled || loading} onChange={event => {
          /**
           * The current value of the input field after a key press.
           */
          const inputValue = event.target.value;

          /**
           * Only allow a valid phone number format as an input.
           *
           * @example
           * +65 1234 567
           *
           * @example
           * 1234 567
           *
           * @example
           * 12345678
           *
           * @example
           * +65-1234-567
           */
          if (!/^[+\d\s\-*#]*$/g.test(inputValue)) {
            return;
          }
          validateAndSetPhoneNumberAndCountry({
            phoneNumber: inputValue.trim()
          });
        }} onPaste={event => {
          event.preventDefault();
          validateAndSetPhoneNumberAndCountry({
            phoneNumber: event.clipboardData.getData("text").trim()
          });
        }} size="large" style={{
          background: "none",
          boxShadow: "none",
          border: "none",
          fontWeight: "bold",
          fontSize: "1.834rem",
          padding: 0
        }} value={dialPadInputValue} data-sentry-element="Input" data-sentry-source-file="dialer.tsx" />
          <div>
            <Tooltip title={!dialPadInputValue?.length ? "No phone number to copy." : !!errorMessage?.length ? "Invalid phone number" : "Copy phone number."} data-sentry-element="Tooltip" data-sentry-source-file="dialer.tsx">
              <Button disabled={disabled || loading || !phoneNumber?.length || !!errorMessage?.length} icon={<Icon src="copy" />} onClick={async () => {
              await navigator.clipboard.writeText(`+${phoneNumber}`);
              setAlertMessage(`+${phoneNumber} copied.`);
            }} shape="circle" style={{
              height: 50,
              width: 50
            }} type="text" data-sentry-element="Button" data-sentry-source-file="dialer.tsx" />
            </Tooltip>
          </div>
        </div>
        {alertMessage?.length && <div className={"flex animate-slide-left justify-center gap-2 text-center text-sm text-tpl-navy"}>
            <CheckCircleFilledIcon className="font-bold" style={{
          color: "var(--semantic-green)"
        }} />
            {alertMessage}
          </div>}
        {errorMessage?.length && <div className={"flex animate-slide-left justify-center gap-2 text-center text-sm text-semantic-red"}>
            <WarningFilledIcon className="font-bold" style={{
          color: "var(--semantic-red)"
        }} />
            {errorMessage}
          </div>}
      </div>
      <DialPad disabled={disabled || loading} onClick={({
      value
    }) => {
      validateAndSetPhoneNumberAndCountry({
        phoneNumber: `${dialPadInputValue}${value}`
      });
    }} data-sentry-element="DialPad" data-sentry-source-file="dialer.tsx" />
      <div className="flex items-center justify-center">
        <Tooltip title={sessionInitationProtocol === "enabled" ? undefined : "Dial using United States phone number."} data-sentry-element="Tooltip" data-sentry-source-file="dialer.tsx">
          <Button data-testid={PlayWrightTestId.Dialer.CALL_BUTTON} disabled={disabled || loading || !phoneNumber?.length || !!errorMessage?.length} icon={<Icon className="!text-[40px] !text-white" src="phone" />} loading={loading} onClick={() => {
          if (!phoneNumber?.length) {
            return;
          }
          onDial({
            phoneNumber: `+${phoneNumber}`
          });
        }} shape="circle" style={{
          backgroundColor: disabled || loading || !phoneNumber?.length || !!errorMessage?.length || !!errorMessage?.length ? "var(--tpl-navy-light)" : sessionInitationProtocol === "enabled" ? "var(--semantic-green)" : "var(--semantic-blue)",
          height: 80,
          width: 80
        }} type="primary" data-sentry-element="Button" data-sentry-source-file="dialer.tsx" />
        </Tooltip>
      </div>
    </div>;
}