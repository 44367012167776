"use client";

/**
 * Third-party libraries.
 */
import { PropsWithChildren, useMemo } from "react";

/**
 * Project components.
 */
import { useAuthenticationContext } from "@/components/client/authentication";
import { Loading } from "@/components/client/loading";
import { Auth0Permission } from "@/components/common/auth0/enumerations";
import { PermissionUtility } from "@/components/common/auth0/utilities";
type PermissionRequiredProps = PropsWithChildren<{
  /**
   * Required permissions to access the component.
   * - allow access to children if no permissions are required.
   */
  requiredPermissions?: Auth0Permission[];
}>;

/**
 * A wrapper component to enforce permissions on the children components.
 * 
 * The children components will only be displayed when the user has the 
 * required permissions.
 */
export const PermissionRequired = function ({
  children,
  requiredPermissions = []
}: PermissionRequiredProps) {
  /**
   * Logged on user details.
   */
  const {
    user,
    isAuthenticated,
    error,
    fetching
  } = useAuthenticationContext();

  /**
   * Check if user has required permissions to access the component..
   * - when no permissions are required, return true
   * - when user has all required permissions, return true
   * - when user does not have all required permissions, return false
   */
  const userHasRequiredPermissions = useMemo(() => {
    if (!user) return false;
    return PermissionUtility.isAuthorized({
      userPermissions: user.permissions,
      requiredPermissions
    });
  }, [user, requiredPermissions]);
  if (fetching) {
    return <Loading size="large" />;
  }
  if (error) return <div>{error.message}</div>;

  /**
   * Redirects the user to the 401 page when one of the following conditions are met:
   * - user is not authenticated (user is null or access token has expired)
   * - user does not have application access
   * - user does not have the required permissions to access the component.
   */
  if (!isAuthenticated() || !user || !user.permissions.includes(Auth0Permission.APPLICATION_ACCESS) || !userHasRequiredPermissions) {
    return null;
  }
  return children;
};