"use client";

/**
 * Third-party libraries.
 */
import { Button, Divider, Layout } from "antd";
import { usePathname, useRouter } from "next/navigation";
import { ReactNode, useEffect, useMemo } from "react";

/**
 * Project components.
 */
import { AgentList, AgentListProps } from "@/components/client/agent";
import { useAuthenticationContext } from "@/components/client/authentication";
import { useApplicationContext } from "@/components/client/context";
import { Auth0Permission } from "@/components/common/auth0/enumerations";
import { ContactsOutlined, ContainerOutlined } from "@ant-design/icons";
import { CallDirection } from "../graphql";

/**
 * Navigation menu item.
 */
type NavigationMenuItem = {
  /**
   * The icon to display.
   */
  icon: ReactNode;
  /**
   * The label to display.
   */
  label: string;
  /**
   * The path to navigate to.
   */
  path: string;
  /**
   * The permissions required to access this menu item.
   */
  requiredPermissions: Auth0Permission[];
};

/**
 * Menu items that show in the sidebar.
 * Used to navigate to different pages.
 */
const navigationMenu: NavigationMenuItem[] = [{
  icon: <ContainerOutlined />,
  label: "Team Inbox",
  path: "/",
  requiredPermissions: []
}, {
  icon: <ContactsOutlined />,
  label: "Phone Book",
  path: "/phone-book",
  requiredPermissions: [Auth0Permission.PHONE_BOOK_VIEW]
}
// {
//   icon: "chart",
//   label: "Analytics",
//   path: "/analytics",
// },
];

/**
 *
 * Global layout for all pages, include sidebar and content areas. Children will displayed in content area.
 * Requires authentication to access this component.
 */
export function DefaultProtectedPageLayoutSidebar() {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const router = useRouter();
  const pathName = usePathname();
  const {
    activeCalls,
    setUserAvailabilityStatus,
    users,
    usersLoading
  } = useApplicationContext();
  const {
    user
  } = useAuthenticationContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const agentList = useMemo<AgentListProps["agents"]>(() => {
    if (!users || usersLoading) {
      return [];
    }
    return (users ?? []).map(user => {
      const activeUserCalls = activeCalls.filter(activeCall => (activeCall.userId || activeCall.user?.id) === user.id) || [];
      return {
        calls: activeUserCalls.map(activeCall => ({
          customer: activeCall.direction === CallDirection.Inbound ? activeCall.fromContact?.displayName || activeCall.from : activeCall.toContact?.displayName || activeCall.to
        })),
        id: user.id,
        name: user.profile.fullName,
        status: user.availability.status,
        statusDateTime: new Date(user.availability.updatedDate)
      };
    }) as AgentListProps["agents"];
  }, [activeCalls, users, usersLoading]);

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Update the user availability status on the header when the users's status
   * changes on the users list.
   */
  useEffect(() => {
    const matchingUser = users.find(_user => _user.email === user?.email);
    if (!matchingUser) {
      return;
    }
    setUserAvailabilityStatus({
      local: true,
      status: matchingUser?.availability.status
    });
  }, [setUserAvailabilityStatus, user?.email, users]);
  return <Layout.Sider className="flex h-full flex-col !gap-3 !bg-white !px-4 !py-5" width={220} style={{
    borderRight: "1px solid rgba(0,0,0,0.1)"
  }} data-sentry-element="unknown" data-sentry-component="DefaultProtectedPageLayoutSidebar" data-sentry-source-file="default-protected-page-layout-sidebar.tsx">
      <div className="flex h-full w-full flex-col gap-3">
        <div className="flex w-full flex-col gap-3 leading-7">
          {navigationMenu.map(navigationMenuItem => {
          return navigationMenuItem.requiredPermissions.every(requiredPermission => user?.permissions.includes(requiredPermission)) ? <Button icon={navigationMenuItem.icon} id={navigationMenuItem.label.replace(" ", "")} key={navigationMenuItem.path} onClick={() => router.push(navigationMenuItem.path)} size="large" style={{
            fontSize: "0.875rem",
            justifyContent: "flex-start"
          }} type={pathName === navigationMenuItem.path ? "primary" : "text"}>
                {navigationMenuItem.label}
              </Button> : null;
        })}
        </div>
        <Divider className="!m-0" data-sentry-element="Divider" data-sentry-source-file="default-protected-page-layout-sidebar.tsx" />
        <div className="w-full overflow-y-auto">
          <AgentList agents={agentList} loading={!agentList?.length && usersLoading} data-sentry-element="AgentList" data-sentry-source-file="default-protected-page-layout-sidebar.tsx" />
        </div>
      </div>
    </Layout.Sider>;
}